(<template>
  <form-template :title="title"
                 :progress="progressActive">
    <p class="wizard-add-skill__description">{{ description }}</p>
    <skill-form class="wizard-add-skill__form"
                @startprogress="progressActive = true"
                @stopprogress="progressActive = false"
                @formsubmitted="catchSubmit">
      <div class="wizard-add-skill__btns-wrapper">
        <button class="sk-btn sk-btn--default wizard-add-skill__btn">{{ $gettext('Add') }}</button>
      </div>
    </skill-form>
  </form-template>
</template>)

<script>
  import {mapActions, mapMutations, mapState} from 'vuex';
  import WizardFormTemplate from '@/components/wizard_components/WizardFormTemplate';
  import SkillForm from '@/components/shared_components/SkillForm';

  export default {
    components: {
      'form-template': WizardFormTemplate,
      'skill-form': SkillForm
    },
    data() { return {progressActive: false}; },
    computed: {
      ...mapState('TTManageSupplierSkill', {
        // errors
        langToErrors: ({errors}) => errors.langTo.errors,
        langToErrorsName: ({errors}) => errors.langTo.name
      }),
      title() { return this.$gettext('Add your language skill'); },
      description() { return this.$gettext('Please add a language skill here including the qualification you have. If you speak more languages you can always add them from your profile page later.'); }
    },
    methods: {
      ...mapMutations('TTManageSupplierSkill', [
        'setError',
        'removeErrors'
      ]),
      ...mapActions('TTManageSupplierSkill', [
        'createSkill'
      ]),
      catchSubmit() {
        this.progressActive = true;
        this.createSkill().then(() => {
          this.progressActive = false;
          this.$router.push(this.$makeRoute({name: 'ServerWizardAddGender'}));
        }).catch((error) => {
          this.removeErrors();
          this.$store.commit('ErrorsStore/removeAllErrors');
          this.progressActive = false;
          if (error?.errors?.length && error.errors.language_pair_id.includes('skill is already exists')) {
            this.setError({
              fieldName: 'langTo',
              errorText: this.$gettext('You have already added this language combination. Please visit your profile page to edit it further.')
            });
            this.$store.commit('ErrorsStore/setError', {name: this.langToErrorsName, errors: this.langToErrors});
            this.$scrollToErrors();
          } else {
            this.$store.dispatch('ModalStore/closeModal');
            setTimeout(() => {
              this.$store.commit('ModalStore/setModal', {
                component: 'error-modal',
                data: {
                  error
                }
              });
            });
          }
        });
      }
    }
  };
</script>

<style>
  .wizard-add-skill__form .skill-form__price-section {
    left: 0;
    width: 100%;
  }

  .wizard-add-skill__form .skill-form__price-title,
  .wizard-add-skill__form .skill-form__price-none {
    text-align: center;
  }

  .wizard-add-skill__form .skill-form__price-none {
    max-width: 370px;
    margin: 0 auto;
  }
</style>

<style scoped>
  .wizard-add-skill__form {
    max-width: 555px;
    margin: 0 auto;
    text-align: left;
  }

  .wizard-add-skill__description {
    max-width: 555px;
    margin: 0 auto;
    padding: 0 20px;
    font-size: 12px;
    text-align: center;
  }

  .wizard-add-skill__btns-wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 20px;
  }

  .wizard-add-skill__btn {
    display: inline-block;
    width: 250px;
    max-width: 250px;
  }
</style>
