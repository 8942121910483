(<template>
  <add-skill />
</template>)

<script>
  import WizardAddSkill from '@/components/wizard_components/WizardAddSkill';

  export default {
    components: {
      'add-skill': WizardAddSkill
    }
  };
</script>
